<template>
  <div>
    <t-modal
      :name="id"
      variant="subscription"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="popup"
    >
      <section class="px-8 pt-10">
        <div class="font-medium text-28px text-oCharcoal">
          {{
            this.details.status === 'Verified'
              ? 'Revert verificatoin of'
              : 'Verify'
          }}
          this
          <span class="lowercase">{{ this.details.report_category }}</span>
          report
        </div>

        <section class="mt-6">
          <t-checkbox
            wrapped
            v-model="adminRemarksUi"
            :label="`Add Remarks`"
            :name="`adminRemarksUi`"
          />
          <div class="-mt-2 text-14px" style="margin-left: 28px;">
            <span class="text-gray-500">
              Adding remarks is optional but recommended.
            </span>
          </div>
        </section>

        <section v-show="adminRemarksUi" class="mt-6">
          <t-textarea v-model="adminRemarks" />
        </section>

        <section class="mt-6">
          <t-checkbox
            wrapped
            v-model="deductPoints"
            :label="`Deduct Points`"
            :name="`deductPoint`"
          />
          <div class="-mt-2 text-14px" style="margin-left: 28px;">
            <span class="text-gray-500">The reported user will lose</span>
            <span class="text-oRed"> {{ getPointDeductionAmount }} </span>
            <span class="text-gray-500">points.</span>
          </div>
        </section>

        <section class="mt-12 ml-5">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <DisplayAvatar
                :avatar="getReportedUserAvatar"
                :width="`4rem`"
                :height="`4rem`"
              />
              <div>
                <div class="font-medium text-14px text-oCharcoal">
                  {{ getReportedUserFullName || '--' }}
                </div>
                <div class="font-medium text-gray-500 text-14px">
                  {{
                    getReportedUserId
                      ? `#${$truncate(getReportedUserId, -5)}`
                      : '--'
                  }}
                </div>
              </div>
            </div>

            <div class="points" :class="getPointDeductionClass">
              {{ getPointDeductionAmount }}
            </div>
          </div>

          <div class="my-3 border-t" />

          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <DisplayAvatar
                :avatar="getReporterUserAvatar"
                :width="`4rem`"
                :height="`4rem`"
              />
              <div>
                <div class="font-medium text-14px text-oCharcoal">
                  {{ getReporterUserFullName || '--' }}
                </div>
                <div class="font-medium text-gray-500 text-14px">
                  {{
                    getReporterUserId
                      ? `#${$truncate(getReporterUserId, -5)}`
                      : '--'
                  }}
                </div>
              </div>
            </div>

            <div class="points green">
              {{ getPointAdditionAmount }}
            </div>
          </div>
        </section>

        <div class="flex items-center justify-end px-4 py-4 mt-12 mb-4">
          <anchor-button variant="secondary" @click="$refs.popup.hide()">
            Cancel
          </anchor-button>

          <anchor-button variant="primary" class="ml-4" @click="executeAction">
            Continue
          </anchor-button>
        </div>
      </section>
    </t-modal>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { VerificationPopupMixin } from './VerificationPopupMixin'

export default {
  name: 'ReportVerificationPopup',
  mixins: [VerificationPopupMixin],
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    // TextInput: () => import('@/components/form/TextInput.vue'),
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mid: 'reportVerificationPopupA',
      isLoading: false,
      deductPoints: false,
      adminRemarks: null,
      adminRemarksUi: false,
    }
  },
  computed: {
    getPointDeductionClass() {
      return this.deductPoints ? 'red' : 'gray'
    },
    getPointAdditionAmount() {
      const addPoint = this.details.user?.add_point ?? 0
      return `+${addPoint}`
    },
    getPointDeductionAmount() {
      const deductPoint = this.details.prev_trip_user?.deduct_point ?? 0
      return `${deductPoint}`
    },
    // Reporter user
    getReporterUserId() {
      return this.details.user ? this.details.user.id : null
    },
    getReporterUserAvatar() {
      return this.details.user ? this.details.user.profile_pic : null
    },
    getReporterUserFullName() {
      return this.details.user ? this.details.user.full_name : null
    },
    // Reported user
    getReportedUserId() {
      return this.details.prev_trip_user ? this.details.prev_trip_user.id : null
    },
    getReportedUserAvatar() {
      return this.details.prev_trip_user
        ? this.details.prev_trip_user.profile_pic
        : null
    },
    getReportedUserFullName() {
      return this.details.prev_trip_user
        ? this.details.prev_trip_user.full_name
        : null
    },
  },
  mounted() {
    EventBus.$on('reportVerificationPopupA', (item) => {
      this.form = {
        remarks: '',
      }
      this.refund = item
    })
  },
}
</script>

<style lang="scss" scoped>
@import '$report-user-card';
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
